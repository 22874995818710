import React, { PropsWithChildren, useState } from "react";

import { Loader } from "lucide-react";

import { cn, formatNumber } from "@/lib/utils.ts";

import { DatePicker, Input, Icon, Text, TextArea, Popover, Dropdown } from "@/app/components";
import { FormSelect } from "@/app/components/input";
import { Metric, MetricDuplicate, metricsStore } from "@/app/screens/metrics-extraction";
import { getMetricType } from "@/app/screens/metrics-extraction/utils.ts";

function cleanupNumber(input: string) {
  // Use regex to remove all characters that are not numbers, commas, or dots
  let cleaned = input.replace(/[^0-9,\.]/g, "");

  // Remove any duplicate commas or dots
  cleaned = cleaned.replace(/[,\.]{2,}/g, ",");

  // Ensure proper formatting:
  // If more than one dot exists, keep the first one and replace subsequent dots with commas
  const dotIndex = cleaned.indexOf(".");
  if (dotIndex !== -1) {
    cleaned = cleaned.slice(0, dotIndex + 1) + cleaned.slice(dotIndex + 1).replace(/\./g, ",");
  }

  return cleaned;
}

function parseMetricValue(value: boolean | number | string | Date, metric: Metric) {
  if (metric.type === "date") {
    return value ? new Date(value) : new Date();
  }
  if (metric.type === "number") {
    if (metric.format !== "percentage")
      value = formatNumber(cleanupNumber(value.toString()).replaceAll(",", "")); //value.toString().replace(/[^,.0-9]/g, "");
    else value = formatNumber(cleanupNumber(value.toString()).replace(/[,%]/g, "")) + " %"; //value.toString().replace(/[^,.0-9]/g, "");
  }
  if (metric.type === "boolean") {
    return metric.value === "Yes";
  }

  return value;
}

const updateMetric = (label, metricUpdate: Partial<Metric>) => {
  if (metricUpdate.type && metricUpdate.value) {
    metricsStore.editMetric(label, metricUpdate);
    return;
  }
  metricsStore.editMetric(label, metricUpdate);
};

export const MetricValue = ({ metric }: { metric: Metric; focus?: boolean }) => {
  const duplicateExists = Boolean((metric?.duplicate as MetricDuplicate[])?.filter((m) => m.active).length);
  const manuallyDate = metric.source === "Manually Added";

  if (metric.type === "date") {
    return (
      <MetricSkeleton metric={metric} containerClassname={"px-6"}>
        <div className={"flex items-center justify-center space-x-3"}>
          <DatePicker
            className={"outline-none"}
            iconRight={""}
            iconLeftClassName={"text-neutral size-4"}
            maxDate={new Date()}
            selected={metric?.value ? new Date(metric.value) : new Date()}
            onChange={(date) => {
              if (date) updateMetric(metric.label, { value: date.toISOString() });
            }}
          />
          <DeactivateMetric label={metric.label} required={metric.required} />
        </div>
      </MetricSkeleton>
    );
  }

  const metricIconSymbol = metric.format === "currency" ? metric.formatInfo.currencySymbol : undefined;
  const value = metric.value;

  if (metric.type === "boolean") {
    const metricValue = metric.value === "Yes";
    return (
      <MetricSkeleton
        metric={metric}
        containerClassname={cn(duplicateExists ? "m-3 rounded-sm bg-red-100 px-3 pt-3" : "px-6")}
      >
        <div className={"flex items-center gap-x-3"}>
          <FormSelect
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            disableInputFocus={true}
            canDelete={false}
            onChange={(value) => {
              updateMetric(metric.label, { value: value[0].label, type: metric.type });
            }}
            placeholder={"Select an option..."}
            styles={{ labelClassName: "bg-white px-0", inputClassName: "caret-transparent" }}
            defaultOptions={[{ value: metricValue, label: metric.value }]}
          />
          {typeof metric?.quotation === "string" && !manuallyDate && (
            <Popover
              childrenContent={
                <QuotationPopover quotation={metric.quotation} quotationSource={metric.quotationSource} />
              }
              placement={"right-end"}
            >
              <Icon
                type={"Eye"}
                onClick={() => {
                  metricsStore.selectQuotation(metric.quotationSource);
                  updateMetric(metric.label, { highlight: !metric.highlight });
                }}
                className={cn(
                  "size-6 cursor-pointer rounded-xs p-1 active:opacity-80",
                  metric.highlight
                    ? "bg-gradient-to-r from-primary to-secondary text-white"
                    : "bg-neutral-100 text-neutral-600",
                )}
              />
            </Popover>
          )}
          <DeactivateMetric
            label={metric.label}
            required={metric.required}
            duplicateIndex={duplicateExists ? -1 : undefined}
          />
        </div>
        {duplicateExists
          ? (metric?.duplicate as MetricDuplicate[])
              ?.filter((m) => m.active)
              .map((duplicate, index) => {
                return (
                  <div key={index} className={"my-2 flex items-center gap-x-3"}>
                    <FormSelect
                      disableInputFocus={true}
                      canDelete={false}
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      placeholder={"Select an option..."}
                      styles={{ labelClassName: "bg-white px-0", inputClassName: "caret-transparent" }}
                      defaultOptions={[{ value: duplicate.value === "Yes", label: duplicate.value }]}
                    />
                    {typeof duplicate?.quotation === "string" && duplicate.source !== "Manually Added" && (
                      <Popover
                        childrenContent={
                          <QuotationPopover
                            quotation={duplicate.quotation}
                            quotationSource={duplicate.quotationSource}
                          />
                        }
                        placement={"right-end"}
                      >
                        <Icon
                          type={"Eye"}
                          onClick={() => {
                            metricsStore.selectQuotation(duplicate.quotationSource);
                            updateMetric(metric.label, {
                              duplicate: metric.duplicate?.map((d, i) => ({ ...d, highlight: i === index })) || [],
                            });
                          }}
                          className={cn(
                            "size-6 cursor-pointer rounded-xs p-1 active:opacity-80",
                            duplicate.highlight
                              ? "bg-gradient-to-r from-primary to-secondary text-white"
                              : "bg-neutral-100 text-neutral-600",
                          )}
                        />
                      </Popover>
                    )}
                    <DeactivateMetric label={metric.label} required={metric.required} duplicateIndex={index} />
                  </div>
                );
              })
          : null}
      </MetricSkeleton>
    );
  }

  return (
    <MetricSkeleton
      metric={metric}
      containerClassname={cn(duplicateExists ? "m-3 rounded-sm bg-red-100 px-3 pt-3" : "px-6")}
    >
      <div className={"flex items-center justify-center space-x-3"}>
        <Input
          value={value}
          placeholder={`Add a metric...`}
          className={duplicateExists ? "border-red" : ""}
          iconRight={typeof metric?.quotation === "string" && !manuallyDate ? "Eye" : undefined}
          iconRightOnClick={() => {
            metricsStore.selectQuotation(metric.quotationSource);
            updateMetric(metric.label, { highlight: !metric.highlight });
          }}
          iconRightPopoverLocation={"right-end"}
          iconRightPopover={<QuotationPopover quotation={metric.quotation} quotationSource={metric.quotationSource} />}
          iconRightClassName={cn(
            "size-6 cursor-pointer rounded-xs p-1 active:opacity-80",
            metric.highlight
              ? "bg-gradient-to-r from-primary to-secondary text-white"
              : "bg-neutral-100 text-neutral-600",
          )}
          iconLeft={metricIconSymbol}
          iconLeftComponent={
            metric.format === "currency" ? (
              <CurrencyDropDown
                icon={metricIconSymbol}
                setCurrency={(currencySymbol) => {
                  metricsStore.editMetric(metric.label, { formatInfo: { ...metric.formatInfo, currencySymbol } });
                }}
              />
            ) : null
          }
          iconLeftClassName={cn("!size-4", metric.format === "percentage" ? "text-neutral" : "text-currency")}
          containerClassName={"w-full"}
          onChange={({ target: { value } }, ref) => {
            const parsedValue = parseMetricValue(value, metric);
            const cursorPositionFromEnd = value.length - ref.selectionStart;
            const newCursorPosition = parsedValue.length - cursorPositionFromEnd;

            updateMetric(metric.label, { value: parsedValue, type: metric.type });
            setTimeout(() => ref.setSelectionRange(newCursorPosition, newCursorPosition), 0);
          }}
        />
        <DeactivateMetric
          label={metric.label}
          required={metric.required}
          duplicateIndex={duplicateExists ? -1 : undefined}
        />
      </div>
      {duplicateExists
        ? (metric?.duplicate as MetricDuplicate[])
            ?.filter((m) => m.active)
            .map((duplicate, index) => {
              return (
                <div key={index} className={"my-2 flex items-center justify-center gap-y-2 space-x-3"}>
                  <Input
                    className={"border-red"}
                    value={duplicate.value}
                    iconRight={
                      typeof duplicate?.quotation === "string" && duplicate.source !== "Manually Added"
                        ? "Eye"
                        : undefined
                    }
                    iconRightOnClick={() => {
                      metricsStore.selectQuotation(duplicate.quotationSource);
                      updateMetric(metric.label, {
                        duplicate: metric.duplicate?.map((d, i) => ({ ...d, highlight: i === index })) || [],
                      });
                    }}
                    iconLeft={metricIconSymbol}
                    iconLeftClassName={cn("!size-4", metric.format === "percentage" ? "text-neutral" : "text-currency")}
                    iconRightPopoverLocation={"right-end"}
                    iconRightPopover={
                      <QuotationPopover quotation={duplicate.quotation} quotationSource={duplicate.quotationSource} />
                    }
                    iconRightClassName={cn(
                      "size-6 cursor-pointer rounded-xs p-1 active:opacity-80",
                      duplicate.highlight
                        ? "bg-gradient-to-r from-primary to-secondary text-white"
                        : "bg-neutral-100 text-neutral-600",
                    )}
                    containerClassName={"w-full"}
                    onChange={({ target: { value } }) => updateMetric(metric.label, { value, type: metric.type })}
                  />
                  <DeactivateMetric label={metric.label} required={metric.required} duplicateIndex={index} />
                </div>
              );
            })
        : null}
      {duplicateExists && (
        <div className={"ml-1 flex items-center space-x-1"}>
          <Icon type={"AlertTriangle"} className={"size-4 text-red"} />
          <Text
            text={"Duplicate metrics detected. Remove any extra entries before submitting."}
            color={"text-red"}
            type={"message"}
            weight={"medium"}
          />
        </div>
      )}
    </MetricSkeleton>
  );
};

const MetricSkeleton = ({ metric, children, containerClassname }: { metric: Metric; containerClassname?: string }) => (
  <div className={cn("flex flex-col", containerClassname)}>
    <div className={"mb-2 flex gap-x-1"}>
      <Text text={metric.label} type={"message"} weight={"medium"} color={"text-neutral-700"} />
      <Text text={`(${metric.source})`} type={"message"} weight={"medium"} color={"text-neutral-400"} />
    </div>
    <>{children}</>
    <TextArea
      showTranscribe={false}
      value={metric.note}
      placeholder={"Add an optional note..."}
      containerClassName={"w-full mt-2"}
      onChange={(note) => {
        updateMetric(metric.label, { note });
      }}
    />
  </div>
);

const DeactivateMetric = ({ duplicateIndex, label, className, required }) => {
  if (required) return null;
  if (duplicateIndex !== undefined)
    return (
      <Icon
        type={"Trash"}
        onClick={() => {
          metricsStore.deleteDuplicate({ label, index: duplicateIndex });
        }}
        className={cn(
          "flex size-7 cursor-pointer select-none items-center justify-center rounded-[7px] bg-neutral-200 p-1.5 text-center hover:bg-neutral-100 active:bg-neutral-50",
          className,
        )}
      />
    );
  return (
    <Icon
      type={"Sub"}
      className={cn(
        "size-7 cursor-pointer select-none rounded-sm bg-neutral-900 p-1 text-white hover:bg-neutral-800",
        required
          ? "bg-neutral-200 text-neutral-600 hover:bg-neutral-200 hover:text-neutral-600 active:bg-neutral-100 active:text-neutral-600"
          : "",
      )}
      onClick={() => {
        updateMetric(label, { active: false });
      }}
    />
  );
};

const QuotationPopover = ({ quotation, quotationSource }) => {
  return (
    <div className={"flex max-w-[320px] flex-col space-y-2 p-2"}>
      <div className={"flex flex-col space-y-1"}>
        <Text text={"Excerpt"} type={"message"} weight={"semibold"} color={"text-neutral-400"} />
        <Text text={`"${quotation}"`} italic type={"message"} noOfLines={2} />
      </div>
      <div className={"flex flex-col space-y-1"}>
        <Text text={"Sourced From"} type={"message"} weight={"semibold"} color={"text-neutral-400"} />
        <div className={"flex items-center"}>
          <Icon
            type={quotationSource == "Email Body" ? "Mail" : "PDFIcon"}
            className={"mr-1 size-5 text-neutral-400"}
          />
          <Text text={quotationSource} weight={"light"} type={"message"} noOfLines={1} color={"text-neutral-400"} />
        </div>
      </div>
    </div>
  );
};

const CurrencyDropDown = ({ icon, setCurrency }) => {
  return (
    <Dropdown
      options={[
        { label: "£ GBP", action: () => setCurrency("GBP") },
        { label: "$ USD", action: () => setCurrency("USD") },
        { label: "€ EUR", action: () => setCurrency("EUR") },
      ]}
      className={cn("-ml-1 h-8")}
    >
      <Dropdown.Button className="flex px-0 outline-none lg:w-8" onClick={(e) => e.stopPropagation()}>
        <Icon type={"Filter Chevron Down"} className={"size-3 text-black"} />
        <Icon type={icon} className={"size-4 text-currency"} />
      </Dropdown.Button>
      <Dropdown.Items className={"-right-4 z-40 mt-2 w-fit min-w-[70px] outline-none 2xl:-right-3.5"}>
        {({ label, action }) => (
          <Dropdown.Item onClick={action}>
            <Text className={cn("flex text-nowrap first-letter:text-currency")} text={label} />
          </Dropdown.Item>
        )}
      </Dropdown.Items>
    </Dropdown>
  );
};
