import React, { useState } from "react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { motion } from "framer-motion";

import { useBreakpoints } from "@/lib/hooks";
import { cn, formatFundingMil } from "@/lib/utils.ts";

import { Icon, Text } from "@/app/components";
import { MeetThePortfolio } from "@/app/screens/investments/components/use-meet-the-portfolio.tsx";

export type CellType = {
  value?: number | string | null | boolean;
  change?: "increase" | "decrease";
  extra?: any;
};

const variants = {
  expanded: { height: "fit-content" },
  collapsed: { height: "fit-content" },
};

export const MTPTable = ({
  data,
  columns,
  expanded,
  hoverable = true,
  tableType,
  onShowMore,
  activeFund,
  visibleItems = 5,
  fnOnRowClick,
  rowClassName = "",
  defaultSortingCol = "navToFundSizePercentage",
  containerClassName = "",
}: {
  data: Array<MeetThePortfolio>;
  columns: Array<ColumnDef<MeetThePortfolio>>;
  expanded?: boolean;
  tableType: string;
  activeFund: string;
  hoverable?: boolean;
  onShowMore: () => void;
  visibleItems?: number;
  rowClassName?: string;
  fnOnRowClick?: (...args) => void | null;
  defaultSortingCol?: string;
  containerClassName?: string;
}) => {
  const { isBigTablet } = useBreakpoints();
  const [sorting, setSorting] = useState<SortingState>([{ id: defaultSortingCol, desc: true }]);
  const table = useReactTable({
    data,
    columns,
    defaultColumn: {},
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableRowSelection: true,
    enableSortingRemoval: true,
    manualExpanding: true,
    state: {
      sorting,
      expanded: {},
    },
  });

  const noResults = table.getRowModel().rows.length === 0;

  return (
    <div className={cn("relative w-full bg-white", noResults ? "rounded-md" : "rounded-lg")}>
      <div className="flow-root w-full">
        <div className={cn("min-h-[84px] w-full pb-3.5", containerClassName)}>
          <table className={cn("relative mb-2 w-full", activeFund === "BC" ? "table-fixed" : "")}>
            <thead>
              {!noResults &&
                table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className={cn("h-fit")}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id}>
                          {header.isPlaceholder ? null : (
                            <div
                              className={cn(
                                "flex items-center justify-center font-medium text-neutral ",
                                header.column.getCanSort() ? "" : "",
                              )}
                            >
                              {flexRender(header.column.columnDef.header, {
                                ...header.getContext(),
                                noResults,
                                tableType,
                                activeFund,
                              })}
                            </div>
                          )}
                          <div
                            className={"mb-3 flex h-5 cursor-pointer items-center justify-center"}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.column.getCanSort() &&
                              ({
                                asc: <Icon type={"UpDownSort"} className={"size-2.5"} />,
                                desc: <Icon type={"DownUpSort"} className={"size-2.5"} />,
                              }[header.column.getIsSorted() as string] ?? (
                                <Icon type={"TableSort"} className={"size-2.5"} />
                              ))}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
            </thead>
            <tbody className="size-full bg-white">
              <>
                {noResults ? (
                  <Text
                    type={"title"}
                    weight={"medium"}
                    color={"text-neutral-300"}
                    text={`No Results in ${tableType}`}
                    className="absolute inset-x-0 bottom-0 top-10.5 flex items-center justify-center text-center"
                  />
                ) : (
                  table
                    .getRowModel()
                    .rows.slice(0, !isBigTablet || expanded ? data.length : visibleItems)
                    .map((row) => {
                      return (
                        <tr
                          key={row.id}
                          className={cn("h-fit min-h-8", hoverable ? "hover:bg-neutral-100" : "", rowClassName)}
                          onClick={() => {
                            if (fnOnRowClick) {
                              fnOnRowClick(row.original);
                            }
                          }}
                        >
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td key={cell.id} className={cn("px-2 py-1.5")}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                )}
              </>
            </tbody>
          </table>
        </div>
        {isBigTablet && data.length > visibleItems && (
          <div
            className={
              "group bottom-0 flex h-8 w-full cursor-pointer items-center justify-center rounded-b-lg border-2 border-white bg-neutral-100"
            }
            onClick={onShowMore}
          >
            <div className={"flex  select-none items-center"}>
              <Icon type={expanded ? "Chevron Up" : "Chevron Down"} className={"size-4.5 group-hover:text-neutral"} />
              <Text text={expanded ? "Show Less" : "Show More"} className={"text-[12px] group-hover:text-neutral"} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const TableNA = ({ textStyle = "", containerClassName = "" }) => {
  return (
    <div className={cn(containerClassName)}>
      <Text
        weight={"medium"}
        text={"N/A"}
        className={cn("select-none whitespace-nowrap text-[12px] font-[500] leading-4 !text-neutral-300", textStyle)}
      />
    </div>
  );
};

export const TableText = ({
  label,
  change,
  onClick = () => null,
  className = "",
  containerClassName = "",
}: {
  label: string | unknown;
  change?: "increase" | "decrease";
  onClick?: () => void;
  className?: string;
  containerClassName?: string;
}) => {
  if (!label || String(label).startsWith("0:") || String(label).startsWith("NaN:"))
    return <TableNA containerClassName={cn("flex items-center", containerClassName)} textStyle={className} />;

  return (
    <div className={cn("flex items-center", containerClassName)}>
      <Text
        weight={"medium"}
        onClick={onClick}
        text={String(label)}
        color={"text-neutral-800"}
        className={cn("text-nowrap lg:text-[12px] 2xl:text-[14px]", className)}
      />
      {Boolean(change) && (
        <Icon
          type={change === "increase" ? "Increase" : "Decrease"}
          className={cn("ml-0.5 size-3", change === "increase" ? "text-green" : "text-red")}
        />
      )}
    </div>
  );
};

export const TableHeader = ({
  label,
  header,
  drawHeader = true,
  onClick = () => null,
  containerClassName = "",
}: {
  label: string;
  header?: any;
  drawHeader?: boolean;
  onClick?: () => void;
  containerClassName?: string;
}) => {
  return (
    <div
      className={cn("relative flex h-24 w-full flex-col", drawHeader ? "h-24" : "h-12", containerClassName)}
      onClick={onClick}
    >
      {drawHeader && <div className={"h-8 w-full border-t-2 border-white"}>{header}</div>}
      <Text
        weight={"medium"}
        text={label}
        className={"mt-0.5 flex h-14 w-full items-center justify-center text-center lg:text-xss xl:text-[12px]"}
      />
    </div>
  );
};

export type ColumnHeaderType = {
  label: string;
  value: string | number;
  valueType: "percentage" | "currency" | "multiplier" | "number";
  containerClassName?: string;
  extraProps?: any;
};

export const ColumnHeader = ({ label, value, valueType, containerClassName, extraProps = {} }: ColumnHeaderType) => {
  return (
    <div className={cn("flex flex-col items-center", containerClassName)}>
      <Text
        weight={"medium"}
        text={label}
        className={"!2xl:text-xss w-full bg-neutral-100 py-0.5 text-center !text-ss"}
      />
      {valueType === "percentage" && (
        <RowWithPercentage
          label={value}
          className={"flex w-full items-center justify-center !text-xss text-neutral-700"}
          {...extraProps}
        />
      )}
      {valueType === "currency" && (
        <RowWithCurrency
          label={value}
          className={"!text-xss text-neutral-700"}
          currencyClassName={"!text-[10px]"}
          {...extraProps}
        />
      )}
      {valueType === "multiplier" && <RowWithMultiplier label={value} {...extraProps} />}
      {valueType === "number" && <TableText label={value} className={"!text-xss text-neutral-700"} {...extraProps} />}
    </div>
  );
};

export const TableLabel = ({ label, containerClassName = "" }) => {
  if (!label) return <TableNA containerClassName={containerClassName} />;
  return (
    <div className={cn(containerClassName)}>
      <Text
        weight={"medium"}
        text={String(label)}
        color={"text-neutral-800"}
        className={"w-fit text-nowrap rounded-sm bg-neutral-100 px-2.5 py-1.5"}
      />
    </div>
  );
};

export const RowListWithSeparator = ({
  list,
  containerClassName,
}: {
  list: Array<{ label: string; important: boolean }> | unknown;
  containerClassName?: string;
}) => {
  if (!Array.isArray(list) || !list.length) return <TableNA containerClassName={containerClassName} />;
  return (
    <div className={cn(containerClassName, "flex flex-wrap items-center")}>
      {list
        .sort((a) => (a.important ? -1 : 1))
        .map(({ label, important }, index) => (
          <div className={"flex items-center text-nowrap"} key={label}>
            {important && <Icon type={"Crown"} className={"mr-1.5 size-4"} />}
            <Text
              text={String(label)}
              color={important ? "text-black" : "text-neutral-800"}
              weight={important ? "bold" : "medium"}
            />
            {list.length - 1 > index && <Icon type={"DotIcon"} className={"-mx-1 size-8 text-[#D9D9D9]"} />}
          </div>
        ))}
    </div>
  );
};

export const RowWithCurrency = ({
  label,
  change,
  currency = "£",
  unit = "million",
  className = "",
  currencyClassName = "",
  containerClassName = "",
}: {
  label: number | string | unknown;
  change?: "increase" | "decrease";
  unit?: "million" | "billion";
  currency?: "$" | "£";
  className?: string;
  currencyClassName?: string;
  containerClassName?: string;
}) => {
  const valueOfReference = unit === "billion" ? 1_000_000_000 : 1_000_000;
  let value = formatFundingMil(Number(label) / valueOfReference, 2);
  if (unit === "billion") {
    value = value.replace("m", "b");
  }

  if (!label)
    return (
      <TableNA containerClassName={cn("flex items-center text-nowrap", containerClassName)} textStyle={className} />
    );

  return (
    <div className={cn("flex items-center text-nowrap", containerClassName)}>
      <Text
        weight={"medium"}
        text={currency}
        className={cn(
          "text-center text-currency lg:mr-px lg:text-[12px] 2xl:mr-0.5 2xl:text-[14px]",
          currencyClassName,
        )}
      />
      <Text
        weight={"medium"}
        text={value}
        color={"text-neutral-800"}
        className={cn("text-center lg:text-[12px] 2xl:text-[14px]", className)}
      />
      {Boolean(change) && (
        <Icon
          type={change === "increase" ? "Increase" : "Decrease"}
          className={cn("ml-0.5 size-3", change === "increase" ? "text-green" : "text-red")}
        />
      )}
    </div>
  );
};

export const RowWithMultiplier = ({
  label,
  change,
  className = "",
  containerClassName = "",
}: {
  change?: "increase" | "decrease";
  label: number | string | unknown;
  className?: string;
  containerClassName?: string;
}) => {
  if (!label || Number(label) === 0) return <TableNA containerClassName={containerClassName} />;
  return (
    <div className={cn("flex items-center text-nowrap", containerClassName)}>
      <Text
        weight={"medium"}
        text={`${formatNumber(Number(label))}x`}
        color={"text-neutral-800"}
        className={cn("text-nowrap lg:text-[12px] 2xl:text-[14px]", className)}
      />
      {Boolean(change) && (
        <Icon
          type={change === "increase" ? "Increase" : "Decrease"}
          className={cn("ml-0.5 size-3", change === "increase" ? "text-green" : "text-red")}
        />
      )}
    </div>
  );
};

export const RowWithPercentage = ({
  label,
  change,
  decimals = 2,
  className,
  containerClassName,
}: {
  label: number | string | unknown;
  change?: "increase" | "decrease";
  decimals?: number;
  className?: string;
  containerClassName?: string;
}) => {
  if (Number.isNaN(label) || Number(label) === 0)
    return <TableNA containerClassName={containerClassName} textStyle={className} />;

  return (
    <div className={cn("flex items-center text-nowrap", containerClassName)}>
      <Text
        weight={"medium"}
        className={cn("text-nowrap lg:text-[12px] 2xl:text-[14px]", className)}
        text={`${formatNumber(Number(label) * 100, decimals)}%`}
        color={"text-neutral-800"}
      />
      {Boolean(change) && (
        <Icon
          type={change === "increase" ? "Increase" : "Decrease"}
          className={cn("ml-0.5 size-3", change === "increase" ? "text-green" : "text-red")}
        />
      )}
    </div>
  );
};

const colorDict = {
  primary: "bg-gradient-to-r from-primary-500 via-primary-400 to-primary-200",
  secondary: "bg-gradient-to-r from-secondary-500 via-secondary-400 to-secondary-200",
  blue: "bg-gradient-to-r from-blue-500 via-blue-400 to-blue-200",
  red: "bg-gradient-to-r from-red-500 via-red-400 to-red-200",
  green: "bg-gradient-to-r from-green-500 via-green-400 to-green-200",
  yellow: "bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-200",
  purple: "bg-gradient-to-r from-purple-500 via-purple-400 to-purple-200",
  orange: "bg-gradient-to-r from-orange-500 via-orange-400 to-orange-200",
};

export const RowWithBar = ({ percentage, color = "red" }) => {
  return (
    <div className="flex w-full items-center">
      <div className="flex h-3.5 w-full items-center justify-end">
        <Text
          text={`${percentage}%`}
          weight={"medium"}
          className="mr-2 text-nowrap text-neutral lg:text-[12px]"
          color={"text-neutral-800"}
        />
        <div className={cn(`h-full rounded-[4px]`, colorDict[color])} style={{ width: `${percentage}%` }} />
      </div>
    </div>
  );
};

function formatNumber(num: number | string | unknown, decimals = 2) {
  if (typeof num !== "number") {
    num = Number(num);
  }
  const roundedNum = Math.round((num as number) * 10 ** decimals) / 10 ** decimals;
  const numStr = roundedNum.toString();
  const parts = numStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
