import React from "react";

import { Action } from "@/gql/graphql";

import { cn, reduceSignalsToFormattedText } from "@/lib/utils.ts";

import { Button, Icon, Text } from "@/app/components";
import { useActionMutation } from "@/app/hooks";
import { getCompanyStatus } from "@/app/misc/helpers";
import { ConfirmModal } from "@/app/screens/modal";
import { AttachmentsCommentsCard } from "@/app/screens/opportunities/opportunity/components/highlights/attachments-comments/attachments-comments-card.tsx";
import { CapitalConcentration } from "@/app/screens/opportunities/opportunity/components/highlights/capital-concentration/capital-concentration.tsx";
import { CashProfile } from "@/app/screens/opportunities/opportunity/components/highlights/cash-profile/cash-profile.tsx";
import { EvReturnCard } from "@/app/screens/opportunities/opportunity/components/highlights/ev-return-card.tsx";
import { ExcitementScoreCard } from "@/app/screens/opportunities/opportunity/components/highlights/excitement-score/excitement-score-card.tsx";
import { InvestedBasecampFundsCard } from "@/app/screens/opportunities/opportunity/components/highlights/invested-basecamp-funds/invested-basecamp-funds-card.tsx";
import { LatestReturnProfileCard } from "@/app/screens/opportunities/opportunity/components/highlights/latest-return-profile/latest-return-profile-card.tsx";
import { RevenueCard } from "@/app/screens/opportunities/opportunity/components/highlights/revenue-card.tsx";
import {
  useHighlights,
  HighlightsProps,
} from "@/app/screens/opportunities/opportunity/components/highlights/use-highlights.tsx";
import { authStore } from "@/app/stores/auth.store";
import modalStore from "@/app/stores/modal.store";
import { Signal as SignalType } from "@/app/types";

import { useOpportunityContext } from "../../opportunity-context";

export const Highlights = ({ company, signal, portCo }: HighlightsProps) => {
  const { excitementScore, latestReturnProfile, investedBasecampFunds, timesFlagged, attachmentsComments } =
    useHighlights({ company, signal });
  return (
    <section data-cy="details-page-highlights">
      {signal && <Signal signal={signal} />}
      <div
        className={cn("grid auto-cols-fr grid-cols-1 gap-3 md:grid-cols-3", portCo ? "lg:grid-cols-4 lg:gap-4" : "")}
      >
        <ExcitementScoreCard data={excitementScore} />
        <LatestReturnProfileCard data={latestReturnProfile} />
        {portCo && <CashProfile company={company} />}
        <InvestedBasecampFundsCard investedBasecampFunds={investedBasecampFunds} timesFlagged={timesFlagged} />
        <RevenueCard company={company} />
        <EvReturnCard company={company} />
        {portCo && <CapitalConcentration company={company} />}
        <AttachmentsCommentsCard data={attachmentsComments} />
      </div>
    </section>
  );
};

const Signal = ({ signal }: { signal: SignalType }) => {
  const { company, signalAssignees, refresh } = useOpportunityContext();
  const { inPipeline, hasSignal } = getCompanyStatus(company);
  const isAssignee = signalAssignees.some(({ id }) => id === authStore.userId);
  const { onSubmit } = useActionMutation({
    onSuccess: async () => {
      if (refresh) await refresh();
      modalStore.close();
    },
  });
  const formattedText = reduceSignalsToFormattedText([signal]);

  return (
    <div className="mb-4 flex gap-2 rounded-lg bg-white p-3">
      <Icon type={"SignalGradient"} className="ml-1 size-6 shrink-0" />
      <div className="">
        <Text innerHtml={formattedText} weight="light" />
        {inPipeline && hasSignal && isAssignee && (
          <Button
            variant="secondary"
            size="md"
            text="Not Relevant"
            className="mt-2 bg-neutral-100 text-[14px]"
            onClick={() =>
              modalStore.open(ConfirmModal, {
                props: {
                  title: "Are you sure this Company is not relevant to you?",
                  subTitle: "By confirming, you will be unassigned from the company",
                  handleConfirm: () =>
                    onSubmit({
                      entityId: company?.id,
                      resurface: "never",
                      assignees: signalAssignees.filter(({ id }) => id !== authStore.userId).map(({ id }) => id),
                      action: Action.Snooze,
                      successMessage: `Successfully unassigned from ${company?.name}`,
                    }),
                },
              })
            }
          />
        )}
      </div>
    </div>
  );
};
