import { action, computed, makeObservable, observable } from "mobx";

export type MetricType = "number" | "date" | "boolean" | "currency" | "string";

export type MetricDuplicate = {
  value: string;
  quotation?: string;
  quotationSource: string;
  source: string;
  highlight: boolean;
  active: boolean;
};

export type CurrencyMetricFormat = {
  currencySymbol: "USD" | "EUR" | "GBP" | "Other";
  multiple: "" | "K" | "M" | "B";
};

export type Metric = {
  id: string;
  type: MetricType;
  label: string;
  active: boolean;
  format?: string;
  formatInfo?: CurrencyMetricFormat | any;
  value: string;
  note?: string;
  highlight: boolean;
  required?: boolean;
  source: string;
  quotation?: string;
  quotationSource?: string;
  duplicate?: Array<MetricDuplicate>;
  category: "manual" | "standard";
};

type MetricsStoreState = {
  selectedCompanyId: string | null;
  selectedCompanyDetails: { name?: string; websiteUrl?: string; image?: string; trelloColumn?: string } | null;
  industry: string | null;
  search: string;
  expandSearch: boolean;
  tab: string;
};

export const standardMetrics: Array<Metric> = [
  {
    id: "asAtDate",
    label: "As at date",
    type: "date",
    value: new Date().toISOString(),
    note: "",
    active: true,
    required: true,
    category: "standard",
    highlight: false,
    quotation: "",
    source: "Manually Added",
  },
  {
    id: "FTE",
    label: "FTEs",
    type: "number",
    format: "integer",
    active: false,
    value: "",
    note: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
  {
    id: "ARR",
    label: "ARR",
    type: "number",
    format: "currency",
    formatInfo: {
      currencySymbol: "GBP",
      multiple: "",
    },
    active: false,
    value: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
  {
    id: "yoyGrowth",
    label: "YoY Growth (in %)",
    type: "number",
    format: "percentage",
    active: false,
    value: "",
    note: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
  {
    id: "grossMargin",
    label: "Gross margin (in %)",
    type: "number",
    format: "percentage",
    active: false,
    value: "",
    note: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
  {
    id: "operatingMargin",
    label: "Operating margin (in %)",
    type: "number",
    format: "percentage",
    active: false,
    value: "",
    note: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
  {
    id: "runwayInMonths",
    label: "Runway (in months)",
    type: "number",
    format: "integer",
    active: false,
    value: "",
    note: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
  {
    id: "cashflowPositive",
    label: "Cashflow positive",
    type: "boolean",
    format: "boolean",
    active: false,
    value: "",
    note: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
  {
    id: "fullyFundedToBreakeven",
    label: "Fully funded to breakeven",
    type: "boolean",
    format: "boolean",
    active: false,
    value: "",
    note: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
  {
    id: "burnRate",
    label: "Burn rate",
    type: "number",
    format: "currency",
    formatInfo: {
      currencySymbol: "GBP",
      multiple: "",
    },
    active: false,
    value: "",
    note: "",
    highlight: false,
    quotation: "",
    category: "standard",
    source: "Manually Added",
  },
];

const currencyMultiple = {
  M: 1e6,
  K: 1e3,
  B: 1e9,
};

const formatCurrency = (metric: Metric) => {
  const calculateAmmount = (currencyMultiple[metric.formatInfo.multiple] || 1) * metric.value;
  return `${calculateAmmount}`;
};

const formatMetricValue = (metric: Metric) => {
  switch (metric.format) {
    case "boolean":
      return metric.value ? "Yes" : "No";
    case "currency":
      return formatCurrency(metric);
    case "date":
    case "number":
      return metric.value;
    default:
      return metric.value;
  }
};

const mapValue = (value: string, type: MetricType) => {
  if (type === "boolean") return value === "Yes";
  return value;
};

class MetricsExtractionStore {
  public isHydrated = false;
  public state: MetricsStoreState = {
    selectedCompanyId: null,
    selectedCompanyDetails: null,
    industry: null,
    search: "",
    expandSearch: false,
    tab: "Email Body",
  };
  public metrics: Record<string, Array<Metric>> = {};

  constructor() {
    makeObservable(this, {
      state: observable,
      metrics: observable,
      setState: action,
      setCompany: action,
      initMetrics: action,
      addMetric: action,
      editMetric: action,
      deleteMetric: action,
      selectQuotation: action,
      deleteDuplicate: action,
      activeQuotations: computed,
      mappedMetrics: computed,
      currentMetrics: computed,
      activeMetrics: computed,
      canSubmit: computed,
    });
  }

  initMetrics = ({ metrics, category = "manual" }: { metrics: Array<any>; category: Metric["category"] }) => {
    if (!this.state.selectedCompanyId) return null;
    const companyId = this.state.selectedCompanyId;
    this.metrics[companyId] = [...standardMetrics];

    if (!metrics.length) return null;
    metrics.forEach((m) => {
      if (m.name === "asAtDate") return;
      const metricIndex = this.metrics[companyId].findIndex((metric) => {
        return m.name == metric.id;
      });
      if (metricIndex > -1) {
        if (!this.metrics[companyId][metricIndex].value) {
          this.metrics[companyId][metricIndex] = {
            ...this.metrics[companyId][metricIndex],
            value: formatMetricValue(m),
            format: m.format,
            formatInfo: m.formatInfo,
            active: true,
            quotation: m.excerpt_of_source,
            quotationSource: m.quotationSource,
            source: "Sourced by LLM",
          } as Metric;
          return;
        }
        if (!this.metrics[companyId][metricIndex].duplicate) {
          this.metrics[companyId][metricIndex].duplicate = [
            {
              highlight: false,
              value: formatMetricValue(m),
              active: true,
              quotation: m.excerpt_of_source,
              quotationSource: m.quotationSource,
              source: "Sourced by LLM",
            },
          ];
        } else {
          this.metrics[companyId][metricIndex].duplicate?.push({
            highlight: false,
            value: formatMetricValue(m),
            active: true,
            quotation: m.excerpt_of_source,
            quotationSource: m.quotationSource,
            source: "Sourced by LLM",
          });
        }
        return;
      }
      this.metrics[companyId].push({
        type: "string",
        label: m.name,
        value: formatMetricValue(m),
        active: true,
        quotation: m.excerpt_of_source,
        quotationSource: m.quotationSource,
        source: "Sourced by LLM",
        category,
      } as Metric);
    });
  };

  setState = (state: Partial<MetricsStoreState>) => {
    this.state = { ...this.state, ...state };
  };

  addMetric = (metric: Partial<Metric>) => {
    if (!this.state.selectedCompanyId) return null;
    if (!this.metrics[this.state.selectedCompanyId]) return null;

    if (!metric.label) throw new Error("Metric label is required");
    const metricExists = this.metrics[this.state.selectedCompanyId].findIndex((m) => m.label === metric.label) > -1;
    if (metricExists) throw new Error("Metric already exists");

    this.metrics[this.state.selectedCompanyId].unshift({
      ...metric,
      highlight: false,
      active: true,
    } as Metric);
  };

  editMetric = (label: string, metric: Partial<Metric>) => {
    if (!this.state.selectedCompanyId || !this.metrics[this.state.selectedCompanyId]) return null;
    const companyId = this.state.selectedCompanyId;
    const index = this.metrics[companyId].findIndex((m) => m.label === label);
    if (index !== -1) {
      this.metrics[companyId][index] = { ...this.metrics[companyId][index], ...metric };
    }
  };

  deleteDuplicate = ({ label, index }: { label: string; index: number }) => {
    if (!this.state.selectedCompanyId || !this.metrics[this.state.selectedCompanyId]) return null;
    const companyId = this.state.selectedCompanyId;
    const metricIndex = this.metrics[companyId].findIndex((m) => m.label === label);
    if (metricIndex !== -1) {
      if (index === -1) {
        this.metrics[companyId][metricIndex].value = this.metrics[companyId][metricIndex].duplicate[0].value;
        this.metrics[companyId][metricIndex].duplicate.splice(0, 1);
        this.metrics[companyId] = JSON.parse(JSON.stringify(this.metrics[companyId]));
        return;
      }

      if (this.metrics[companyId][metricIndex].duplicate) {
        this.metrics[companyId][metricIndex].duplicate.splice(index, 1);
        this.metrics[companyId] = JSON.parse(JSON.stringify(this.metrics[companyId]));
      }
    }
  };

  deleteMetric = (label: string) => {
    if (!this.state.selectedCompanyId || !this.metrics[this.state.selectedCompanyId]) return null;
    const companyId = this.state.selectedCompanyId;
    const index = this.metrics[companyId].findIndex((m) => m.label === label);
    if (index !== -1) {
      this.metrics[companyId].splice(index, 1);
    }
  };

  get mappedMetrics() {
    if (!this.state.selectedCompanyId) return {};

    const res = this.metrics[this.state.selectedCompanyId]
      .filter(({ active }) => active)
      .reduce(
        (acc, metric) => {
          const { id, value } = metric;
          let newValue: any = value;

          if (!id || !value) return acc;
          if (metric.type === "number") newValue = Number(value.toString().replace(/[%,]/g, ""));

          if (id === "asAtDate") acc = { ...acc, [id]: value };
          return { ...acc, data: { ...acc.data, [id]: { ...metric, value: newValue } } };
        },
        { data: {} },
      );

    res["data"] = JSON.stringify(res["data"]);
    return res;
  }

  get currentMetrics() {
    if (!this.state.selectedCompanyId) return { standard: [...standardMetrics], manual: [] };
    return (
      this.metrics[this.state.selectedCompanyId]
        ?.toSorted((a, b) => (a.active && !b.active ? -1 : 1))
        .filter(({ label }) => label.toLowerCase().includes(this.state.search?.toLowerCase()))
        .reduce<{ standard: Metric[]; manual: Metric[] }>(
          (acc, metric) => {
            if (metric.category === "standard") {
              return { ...acc, standard: [...acc.standard, metric] };
            }
            //  && metric.source !== "Sourced by LLM" is added interim for V1 where we only want STANDARD metrics
            if (metric.category === "manual" && metric.source !== "Sourced by LLM") {
              return { ...acc, manual: [...acc.manual, metric] };
            }
            return acc;
          },
          { standard: [], manual: [] },
        ) || { standard: [...standardMetrics], manual: [] }
    );
  }

  get activeMetrics() {
    if (!this.state.selectedCompanyId || !this.metrics[this.state.selectedCompanyId])
      return { standard: [], manual: [], llm: [] };
    return (
      this.metrics[this.state.selectedCompanyId]
        ?.filter(({ active }) => Boolean(active))
        .filter((m) => m.label.toLowerCase().includes(this.state.search?.toLowerCase()))
        .reduce(
          (acc, metric) => {
            if (metric.category === "standard") {
              return { ...acc, standard: [...acc.standard, metric] };
            }
            //  && metric.source !== "Sourced by LLM" is added interim for V1 where we only want STANDARD metrics
            if (metric.category === "manual" && metric.source !== "Sourced by LLM") {
              return { ...acc, manual: [...acc.manual, metric] };
            }
            return acc;
          },
          { standard: [], manual: [] },
        ) || { standard: [], manual: [] }
    );
  }

  get anyDuplicate() {
    if (!this.state.selectedCompanyId || !this.metrics[this.state.selectedCompanyId]) return false;
    return [...this.activeMetrics.standard, ...this.activeMetrics.manual].some(
      (metric) => metric.duplicate && metric.duplicate.length > 0,
    );
  }

  setCompany = (companyId: string, companyDetails: any) => {
    const oldMetrics = this.metrics?.[this.state.selectedCompanyId] || standardMetrics;
    if (this.metrics?.[this.state.selectedCompanyId]) delete this.metrics[this.state.selectedCompanyId];
    this.metrics[companyId] = [...oldMetrics];
    this.state.selectedCompanyId = companyId;
    this.state.selectedCompanyDetails = companyDetails;
  };

  selectQuotation = (source?: string) => {
    if (!this.state.selectedCompanyId || !this.metrics[this.state.selectedCompanyId]) return;
    this.metrics[this.state.selectedCompanyId].forEach((metric) => {
      metric.highlight = false;
      if (metric.duplicate) {
        metric.duplicate.forEach((d) => {
          d.highlight = false;
        });
      }
    });
    if (source) this.state.tab = source;
  };

  get activeQuotations() {
    if (!this.state.selectedCompanyId || !this.metrics[this.state.selectedCompanyId]) return [];
    const metrics = Array.from(this.metrics[this.state.selectedCompanyId]) || [];
    return metrics.reduce((acc, metric) => {
      if (metric.highlight && metric.quotation) {
        return [...acc, { label: metric.label, quotation: metric.quotation, source: metric.quotationSource }];
      }
      if (metric.duplicate) {
        return [
          ...acc,
          ...metric.duplicate
            .filter((d) => d.highlight && d.quotation)
            .map((d) => ({ label: metric.label, quotation: d.quotation, source: d.quotationSource })),
        ];
      }
      return acc;
    }, []);
  }

  get canSubmit() {
    if (!this.state.selectedCompanyId || !this.metrics[this.state.selectedCompanyId]) return false;
    return (
      this.metrics[this.state.selectedCompanyId].some((metric, idx) => {
        if (metric.id === "asAtDate") return false;
        return metric.active && metric.value;
      }) && !this.anyDuplicate
    );
  }
}

export const metricsStore = new MetricsExtractionStore();
