import { ArrowDownToLine, ArrowRightToLine, ArrowUpToLine } from "lucide-react";
import { DateTime } from "luxon";

import { capitalizeWords, formatFundingMil } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import continentsJson from "@/data/continents.json";
import countriesJson from "@/data/countries.json";

export const getOptionFromValue = (items, value) => items.find((item) => item.value === value);

export type OptionType = {
  value: string;
  label: string;
};

export const sentimentFields: Array<Array<Record<string, any>>> = [
  [
    {
      id: "RooFLitG1vc5",
      ref: "a99f526120028601",
      name: "Excitement",
      type: "opinion_scale",
      title: "Excitement: Overall, how excited are you about this investment?",
      field_id: "excitement_opinion_scale",
      field_hash: "excitement_opinion_scale",
      group_id: "excitement",
      properties: {
        steps: 11,
        labels: {
          left: "Not at all excited",
          right: "Extremely excited",
        },
        start_at_one: false,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "q2coCuOpKU69",
      ref: "18016074430e06cf",
      name: "Excitement",
      type: "short_text",
      title: "Excitement: Describe your excitement level in 1-2 sentences.",
      field_id: "excitement_short_text",
      group_id: "excitement",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "goG3ZyZx4ovs",
      ref: "5aa9871da46b1b58",
      name: "Vision",
      type: "opinion_scale",
      title: "Vision: What do they believe that is not visible (yet) to others?",
      field_id: "vision_opinion_scale",
      group_id: "vision",
      properties: {
        steps: 10,
        labels: {
          left: "Soap Opera",
          right: "Pratical Science Fiction",
        },
        start_at_one: true,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "R3rcrwEdLqWT",
      ref: "0465c070c03a06bc",
      name: "Vision",
      type: "short_text",
      title: "Vision: Because ...",
      field_id: "vision_short_text",
      group_id: "vision",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "mRad0S8bSrEC",
      ref: "963e254bc9af1c1c",
      name: "Market",
      type: "opinion_scale",
      title: "Market: What happens if they are right and can deliver? How big can this be?",
      field_id: "market_opinion_scale",
      group_id: "market",
      properties: {
        steps: 10,
        labels: {
          left: "Lifestyle Business",
          right: "Fund Returner",
        },
        start_at_one: true,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "XBlJz5bJ84CA",
      ref: "dcfd67ae31d8a9b6",
      name: "Market",
      type: "short_text",
      title: "Market: Because ...",
      field_id: "market_short_text",
      group_id: "market",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "EcX7KeGVITWt",
      ref: "9474d844224adb6d",
      name: "Product",
      type: "opinion_scale",
      title: "Product: Do they have strong product/technology & operations? What makes us believe they can execute?",
      field_id: "product_opinion_scale",
      group_id: "product",
      properties: {
        steps: 10,
        labels: {
          left: "Incremental",
          right: "Game Changing",
        },
        start_at_one: true,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "k4VAEvZN9XPA",
      ref: "ac7957a58e85f99a",
      name: "Product",
      type: "short_text",
      title: "Product: Because ...",
      field_id: "product_short_text",
      group_id: "product",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "wnDkdfcHf32Z",
      ref: "787b275e428e63b8",
      name: "Team",
      type: "opinion_scale",
      title: "Team: What is special about the team? Why them?",
      field_id: "team_opinion_scale",
      group_id: "team",
      properties: {
        steps: 10,
        labels: {
          left: "Average",
          right: "Outliers",
        },
        start_at_one: true,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "xlZRoeGut5ol",
      ref: "c9b1535556770157",
      name: "Team",
      type: "short_text",
      title: "Team: Because ...",
      field_id: "team_short_text",
      group_id: "team",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "ygYR2LCYOAKb",
      ref: "fc1afc3c-e5d4-4175-bd7b-57c66465ad3a",
      name: "Fundraising",
      type: "opinion_scale",
      title: "Fundraising: How do you rate the founder's ability to fundraise?",
      field_id: "fundraising_opinion_scale",
      group_id: "fundraising",
      properties: {
        steps: 10,
        labels: {
          left: "Average",
          right: "Outliers",
        },
        start_at_one: true,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "aRPcNZ63CaCY",
      ref: "25485737-77d3-4eff-a887-8b56ecc776a7",
      name: "Fundraising",
      type: "short_text",
      title: "Fundraising: Because ...",
      field_id: "fundraising_short_text",
      group_id: "fundraising",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "EnZT6gOqxdY2",
      ref: "fee5c7e8b57805cf",
      name: "Timing",
      type: "opinion_scale",
      title: "Timing: Why now is the right time? How fast is the market & competition moving?",
      field_id: "timing_opinion_scale",
      group_id: "timing",
      properties: {
        steps: 10,
        labels: {
          left: "Too Late/Early",
          right: "Perfect",
        },
        start_at_one: true,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "ucLSowqo6bAT",
      ref: "fe289d82cfc71212",
      name: "Timing",
      type: "short_text",
      title: "Timing: Because ...",
      field_id: "timing_short_text",
      group_id: "timing",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "lSRfnyasY5m5",
      ref: "c654f930d1a35442",
      name: "Fit",
      type: "opinion_scale",
      title: "Fit: Why us? How can we help",
      field_id: "fit_opinion_scale",
      group_id: "fit",
      properties: {
        steps: 10,
        labels: {
          left: "Stretch",
          right: "Sweet Spot",
        },
        start_at_one: true,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "BNpil3ensxpS",
      ref: "9458c8f5731d326b",
      name: "Fit",
      type: "short_text",
      title: "Fit: Because ...",
      field_id: "fit_short_text",
      group_id: "fit",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "zZVS4k7yBZAq",
      ref: "f9caa864-da11-4d55-b17f-2c5fabd30285",
      name: "Opportunity",
      type: "opinion_scale",
      title: "Opportunity: Is this an uncapped opportunity?",
      field_id: "opportunity_opinion_scale",
      group_id: "opportunity",
      properties: {
        steps: 10,
        start_at_one: true,
      },
      validations: {
        required: false,
      },
    },
    {
      id: "p2DPwVIVAAFN",
      ref: "2c619e1f-595a-4853-b7ce-35d2ce061793",
      name: "Opportunity",
      type: "short_text",
      title: "Opportunity because...",
      field_id: "opportunity_short_text",
      group_id: "opportunity",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "jrcTsH0guTVf",
      ref: "526c4351678729d6",
      name: "Concerns",
      type: "multiple_choice",
      title: "Concerns: What are your biggest concerns about this investment?",
      field_id: "concerns_multiple_choice",
      group_id: "concerns",
      properties: {
        choices: [
          {
            id: "ZDKheR1nmzUF",
            ref: "96efe68de2f7819d",
            label: "Quality of founder(s)",
          },
          {
            id: "XQMwNRKPO9jQ",
            ref: "05b61d820ced5a12",
            label: "Product/technology is weak",
          },
          {
            id: "Lwtr9NTOOLwI",
            ref: "6a09fa2ad7af94c8",
            label: "Technology is extremely hard (too hard?) to build",
          },
          {
            id: "rHQ5HT1ZZOHn",
            ref: "3c1e7cb803070bd8",
            label: "Commercial strategy (distribution)",
          },
          {
            id: "QjvDM9CQSo7s",
            ref: "4afbebc4d38935a9",
            label: "Lack of monetisation",
          },
          {
            id: "NJw0ZIcmkwU3",
            ref: "0ee5eb3b49bdda84",
            label: "Market (or sub-market) is too small",
          },
          {
            id: "lLDiPiQQRaeo",
            ref: "00362338d732dcd5",
            label: "Market is dying (too late)",
          },
          {
            id: "io4quP74i1Se",
            ref: "d0b909b2c719dfef",
            label: "Market is too nascent (too early)",
          },
          {
            id: "tr29aRYpLtsx",
            ref: "d4f2e01915bec890",
            label: "Lack of bigger vision",
          },
          {
            id: "HzJ9XdrkdMKu",
            ref: "50d050ae7084441f",
            label: "No defensibility",
          },
          {
            id: "TDxoIw18IPCG",
            ref: "526c4351678729123",
            label: "Other",
          },
        ],
        randomize: false,
        allow_other_choice: true,
        vertical_alignment: false,
        allow_multiple_selection: true,
      },
      validations: {
        required: false,
      },
    },
    {
      id: "123432412",
      ref: "526c4351678729123",
      name: "Other",
      type: "short_text",
      title: "Other concern...",
      field_id: "concerns_short_text",
      group_id: "concerns",
      validations: {
        required: false,
      },
    },
  ],
  [
    {
      id: "UbYz9nGZRCcy",
      ref: "a9dc42c52dd1a027",
      name: "Return",
      type: "multiple_choice",
      title: "Return : What return do you expect from this investment?",
      field_id: "return_multiple_choice",
      group_id: "return",
      properties: {
        choices: [
          {
            id: "OS45HBncuZqv",
            ref: "99597592239c13d9",
            label: "<3x",
          },
          {
            id: "afPjLo2xNGeS",
            ref: "385dca56833cb4e6",
            label: "3x-5x",
          },
          {
            id: "chjFs6BPUVU3",
            ref: "b10d394093e408ad",
            label: "5x-10x",
          },
          {
            id: "r6vew1JXrWNu",
            ref: "53220aaf3884ce3b",
            label: ">10x",
          },
        ],
        randomize: false,
        allow_other_choice: false,
        vertical_alignment: false,
        allow_multiple_selection: false,
      },
      validations: {
        required: true,
      },
    },
    {
      id: "bEqlPQglKocN",
      ref: "e6c7ee28ce3e162a",
      name: "Return",
      type: "short_text",
      title: "Return: Because ...",
      field_id: "return_short_text",
      group_id: "return",
      validations: {
        required: false,
      },
    },
  ],
];

export const sources = [
  {
    value: "T&R",
    label: "Track & Revisit",
    fullName: "Track and Revisit",
    color: "bg-orange-500",
  },
  {
    value: "BC",
    label: "Basecamp",
    fullName: "Basecamp",
    color: "bg-blue-500",
  },
  {
    value: "Web",
    label: "Web",
    fullName: "Web chrome plugin",
    color: "bg-green-500",
  },
  {
    value: "Email",
    label: "Email",
    fullName: "Email source",
    color: "bg-cyan-500",
  },
];

export const priorities = [
  {
    label: "Low",
    value: "low",
    icon: ArrowDownToLine,
  },
  {
    label: "Medium",
    value: "medium",
    icon: ArrowRightToLine,
  },
  {
    label: "High",
    value: "high",
    icon: ArrowUpToLine,
  },
];

export const signals = [
  {
    label: "Just Raised",
    value: "just_raised",
    description: "Raised a round in the last 14 months!",
  },
  {
    label: "Might Raise",
    value: "might_raise",
    description: "Didn't raise a round in over 18 months, might raise soon!",
  },
  // {
  //   label: "Fast Growth",
  //   value: "employee_growth",
  //   description: "High employee growth!",
  // },
  {
    label: "Manually Added",
    value: "manual_web",
    description: "Manually added by someone!",
  },
  // {
  //   label: "Should Raise",
  //   value: "should_raise",
  //   description: "Should raise a new round",
  // },
];

export const targetFund: OptionType[] = [
  {
    value: "LocalGlobe",
    label: "LocalGlobe",
  },
  {
    value: "Latitude",
    label: "Latitude",
  },
  {
    value: "Solar",
    label: "Solar",
  },
];

export const stages: OptionType[] = [
  {
    value: "NOT YET RAISED",
    label: "NOT YET RAISED",
  },
  {
    value: "ANGEL",
    label: "ANGEL",
  },
  {
    value: "PRE-SEED",
    label: "PRE-SEED",
  },
  {
    value: "SEED",
    label: "SEED",
  },
  {
    value: "SERIES A",
    label: "SERIES A",
  },
  {
    value: "SERIES B",
    label: "SERIES B",
  },
  {
    value: "SERIES C",
    label: "SERIES C",
  },
  {
    value: "SERIES D",
    label: "SERIES D",
  },
  {
    value: "SERIES E",
    label: "SERIES E",
  },
  {
    value: "SERIES F",
    label: "SERIES F",
  },
  {
    value: "SERIES G",
    label: "SERIES G",
  },
  {
    value: "SERIES H",
    label: "SERIES H",
  },
  {
    value: "SERIES I",
    label: "SERIES I",
  },
  {
    value: "SPINOUT",
    label: "SPIN OUT",
  },
];

export const formCategories: OptionType[] = [
  { value: "Investment Presentation", label: "Investment Presentation" },
  { value: "Developing Conviction", label: "Developing Conviction" },
  { value: "Milestone/Board Meeting", label: "Milestone/Board Meeting" },
  { value: "Practice Pitch", label: "Practice Pitch" },
  { value: "Catch-up", label: "Catch-up" },
  { value: "Talent", label: "Talent" },
  { value: "Test Chemistry", label: "Test Chemistry" },
  {
    value: "Other",
    label: "Other",
  },
];

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const notAvailableString = "N/A";
export const descriptionItems = [
  {
    Icon: (props) => <Icon type={"LastRound"} {...props} />,
    title: "Last Round",
    value: (company) =>
      (company.fundingRounds?.items &&
        company.fundingRounds?.items.length > 0 &&
        capitalizeWords(company.fundingRounds?.items[0]?.round, true)) ||
      notAvailableString,
  },
  {
    Icon: (props) => <Icon type={"LastRoundAmount"} {...props} />,
    title: "Last Round Amount",
    value: (company) => (company.amountUsdMillion ? formatFundingMil(company.amountUsdMillion) : notAvailableString),
  },
  {
    Icon: (props) => <Icon type={"LastRoundLead"} {...props} />,
    title: "Last Round Lead",
    value: (company) => {
      const leadInvestor =
        company.fundingRounds?.items &&
        company.fundingRounds?.items.length > 0 &&
        company.fundingRounds?.items[0].investors?.items?.find((fr) => fr.lead)?.investor;
      if (!leadInvestor) return notAvailableString;
      else return leadInvestor.name;
    },
  },
  {
    Icon: (props) => <Icon type={"Calendar"} {...props} />,
    title: "Last Round Date",
    value: (company) =>
      company.lastFundingDate ? DateTime.fromISO(company.lastFundingDate).toFormat("yyyy-MM-dd") : notAvailableString,
  },
  {
    Icon: (props) => <Icon type={"TotalRaised"} {...props} />,
    title: "Total Raised",
    value: (company) =>
      company.totalFunding
        ? formatFundingMil(
            (company.fundingRounds?.items || [])
              .map((round) => parseFloat(round.amountUsdMillion))
              .filter((round) => !isNaN(round))
              .reduce((a, b) => a + b, 0)
              .toFixed(2),
          )
        : notAvailableString,
  },
  {
    Icon: (props) => <Icon type={"Employees"} {...props} />,
    title: "Employees",
    value: (company) => company.employeesLatest || notAvailableString,
  },
];

export const sortOptionsCompany = [
  {
    label: "Last round $",
    value: "-amount_usd_million",
  },
  {
    label: "Total Funding $",
    value: "-total_funding",
  },
  {
    label: "Dealroom Rating",
    value: "-dealroom_rating",
  },
  {
    label: "Last Funding Date",
    value: "last_funding_date",
  },
  {
    label: "Newest",
    value: "-created_utc",
  },
];

export const sortOptionsOpportunity = [
  {
    label: "Nazare",
    value: "default",
  },
  {
    label: "Newest",
    value: "newest",
  },
  {
    label: "Oldest",
    value: "oldest",
  },
];

export const defaultTrelloColumns = {
  LocalGlobe: "558c7d47afd9cbf4ccc4c969",
  Latitude: "5bda26969461ac8bbed1d0f8",
  Solar: "637646faa0bfe00221715461",
};

export const trelloColumns = [
  { value: "558c7d47afd9cbf4ccc4c969", label: "LG Test Chemistry & EV Filter" },
  { value: "59372e2427706549525b33d7", label: "LG Developing Conviction" },
  { value: "55f74f188189b52465970cb2", label: "LG Fund Raising/Inbound" },
  {
    value: "5bda26969461ac8bbed1d0f8",
    label: "LT - Test Chemistry/10x Filter",
  },
  { value: "5bda298f418a8f6cd8e10d12", label: "LT Developing Conviction" },
  { value: "5e56f17fbcd8234caf0f393e", label: "LT Fund Raising/Inbound" },
  { value: "637646faa0bfe00221715461", label: "Solar Pipeline - Chemistry" },
  { value: "62c6c4f042096b8c33418d79", label: "Solar Prospects" },
  { value: "5bdae47c6c0728111d122923", label: "LT - Making Investment Offer" },
  { value: "5626767dccf2aa74870b6bab", label: "LG Making Investment Offer" },
  { value: "63764680c2233703ffa09dd2", label: "Solar Making Investment Offer" },
];

export const continents: OptionType[] = continentsJson.map((item) => ({
  value: item,
  label: item,
}));

export const countries: OptionType[] = countriesJson.map((item) => ({
  value: item,
  label: item,
}));

export const industries: OptionType[] = [
  {
    label: "AI & Machine Learning",
    value: "AI & Machine Learning",
  },
  {
    label: "Beauty & Fashion",
    value: "Beauty & Fashion",
  },
  {
    label: "Business Operations & Automation",
    value: "Business Operations & Automation",
  },
  {
    label: "Climate & Environment",
    value: "Climate & Environment",
  },
  {
    label: "Data, Design & Developer Tools",
    value: "Data, Design & Developer Tools",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Finance & Payments",
    value: "Finance & Payments",
  },
  {
    label: "Food & Agriculture",
    value: "Food & Agriculture",
  },
  {
    label: "Future of Work",
    value: "Future of Work",
  },
  {
    label: "Health",
    value: "Health",
  },
  {
    label: "HR & Talent",
    value: "HR & Talent",
  },
  {
    label: "Infrastructure, Security & Privacy",
    value: "Infrastructure, Security & Privacy",
  },
  {
    label: "Insurance",
    value: "Insurance",
  },
  {
    label: "Manufacturing & Industry 4.0",
    value: "Manufacturing & Industry 4.0",
  },
  {
    label: "Marketplaces & Aggregators",
    value: "Marketplaces & Aggregators",
  },
  {
    label: "Media & Entertainment",
    value: "Media & Entertainment",
  },
  {
    label: "Real Estate & Construction",
    value: "Real Estate & Construction",
  },
  {
    label: "Retail & Ecommerce",
    value: "Retail & Ecommerce",
  },
  {
    label: "Robotics & Frontier tech",
    value: "Robotics & Frontier tech",
  },
  {
    label: "Transportation & Logistics",
    value: "Transportation & Logistics",
  },
  {
    label: "Travel & Hospitality",
    value: "Travel & Hospitality",
  },
  {
    label: "Web3",
    value: "Web3",
  },
];
